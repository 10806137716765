import React, {useState, useEffect} from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
} from 'reactstrap';
import CommentTable from './Comments';
import Spinner from '../../../components/waiting';
import {useExamQuestionsData} from '../../../hooks/useApiExams';

const QFormatHeading = props => {
    if (props.disabled) return null;
    return (
        <>
            <FormGroup row>
                <Label sm={3} for='questionFormat'>
                    Format
                </Label>
                <Col sm={9}>
                    <Input
                        type='select'
                        name='format'
                        id='questionFormat'
                        onChange={props.onChange}
                        value={props.format}
                    >
                        {props.formats.map(f => (
                            <option key={f}>{f}</option>
                        ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} for='questionHeading'>
                    Heading
                </Label>
                <Col sm={9}>
                    <Input
                        name='heading'
                        id='questionHeading'
                        onChange={props.onChange}
                        value={props.heading}
                    />
                </Col>
            </FormGroup>
        </>
    );
};

const MCQPrompt = props => {
    if (props.disabled) return null;
    return (
        <>
            <FormGroup row>
                <Label sm={3} for='mcImage'>
                    Image
                </Label>
                <Col sm={9}>
                    <Input
                        type='select'
                        name='heading'
                        id='mcImage'
                        onChange={props.onChange}
                        value={props.prompt ? props.prompt : ''}
                    >
                        {props.options.map(i => (
                            <option key={i}>{i}</option>
                        ))}
                    </Input>
                </Col>
            </FormGroup>
        </>
    );
};

const QPageCount = props => {
    if (props.disabled) return null;
    return (
        <>
            <FormGroup row>
                <Label sm={3} for='questionPageCount'>
                    Page Count
                </Label>
                <Col sm={9}>
                    <Input
                        type='number'
                        name='page_count'
                        id='questionPageCount'
                        onChange={props.onChange}
                        value={props.page_count ? props.page_count : 0}
                    />
                </Col>
            </FormGroup>
        </>
    );
};

const QRange = props => {
    if (props.disabled) return null;
    return (
        <FormGroup row>
            <Label sm={3} for='questionRange'>
                Range
            </Label>
            <Col sm={9}>
                <Input
                    type='number'
                    name='range'
                    id='questionRange'
                    onChange={props.onChange}
                    value={props.range}
                />
            </Col>
        </FormGroup>
    );
};

const QWeight = props => {
    if (props.disabled) return null;
    return (
        <FormGroup row>
            <Label sm={3} for='questionWeight'>
                Weight
            </Label>
            <Col sm={9}>
                <Input
                    type='number'
                    name='weight'
                    id='questionWeight'
                    onChange={props.onChange}
                    value={props.weight}
                />
            </Col>
        </FormGroup>
    );
};

const promptImages = [
    '',
    'AP_BIO_Q1a.PNG',
    'AP_BIO_Q5b.PNG',
    'AP_BIO_Q6a.PNG',
    'AP_CHEM_Q4a.PNG',
    'AP_CHEM_Q6a.PNG',
    'CALC_AB_Q1.PNG',
    'CALC_AB_Q3.PNG',
    'CALC_AB_Q4.PNG',
    'CALC_AB_Q5b.PNG',
    'CALC_BC_Q2.PNG',
    'CALC_BC_Q6.PNG',
    'DoubleBubble.PNG',
    'DoubleBubblev2.PNG',
    'KY-BIO-2B.PNG',
    'KY-BIO-5C.PNG',
    'KY-P1-3B.PNG',
    'KY-P1-3D.PNG',
    'KY-CHEM-2A.PNG',
    'KY-CHEM-2Bii.PNG',
    'KY-CHEM-3A.PNG',
    'KY-CHEM-3C.PNG',
    'KY-CHEM-4B.PNG',
    'MIEP1-1A.PNG',
    'MIEP1-1Av2.PNG',
    'MIEP1-1C.PNG',
    'MIEP1-1Cv2.PNG',
    'MIEP1-1a.PNG',
    'MIEP1-1c.PNG',
    'MIEP1-2Bi.PNG',
    'MIEP1-2BiI.PNG',
    'MIEP1-2Biiv2.PNG',
    'MIEP1-2Biv2.PNG',
    'MIEP1-2D.PNG',
    'MIEP1-2Dv2.PNG',
    'MIEP1-2b.PNG',
    'MIEP1-2d.PNG',
    'MIEP1-3A.PNG',
    'MIEP1-3Av2.PNG',
    'MIEP1-3C.PNG',
    'MIEP1-3Cv2.PNG',
    'MIEP1-3a.PNG',
    'MIEP1-3c.PNG',
    'MIEP1-4b.PNG',
    'MIEP1-5C.PNG',
    'MIEP1-5Cv2.PNG',
    'MIEP1-5ai.PNG',
    'MIEP1-5c.PNG',
    'MIEP1P2-Double Bubble MC.PNG',
    'MIEP2-1d.PNG',
    'MIEP2-2ai.PNG',
    'MIEP2-2b.PNG',
    'MIEP2-3b.PNG',
    'MIEP2-3c.PNG',
    'MIEPC-EM-1D.PNG',
    'MIEPC-EM-1Dv2.PNG',
    'MIEPC-EM-1Dv3.PNG',
    'MIEPC-EM-1E.PNG',
    'MIEPC-EM-1Ev2.PNG',
    'MIEPC-EM-1F.PNG',
    'MIEPC-EM-1Fv2.PNG',
    'MIEPC-EM-2A.PNG',
    'MIEPC-EM-2Av2.PNG',
    'MIEPC-M-3D.PNG',
    'MIEPC-M-3Dv2.PNG',
    'MIEPCEM-1d.PNG',
    'MIEPCEM-1e.PNG',
    'MIEPCEM-1f.PNG',
    'MIEPCEM-2a and 2b.PNG',
    'MIEPCEM-2d.PNG',
    'MIEPCM-1b.PNG',
    'MIEPCM-2b.PNG',
    'MIEPCM-2c.PNG',
    'MIEPCM-3d.PNG',
    'MIESTATS6c.PNG',
    'MIESTATS6eii.PNG',
    'PHYS1-1B and 3CiandCii.PNG',
    'PHYS1-2Ci.PNG',
    'PHYS1-3A.PNG',
    'PHYS1-4BiandBii.PNG',
    'PHYS1-5A.PNG',
    'STATS_Q1.PNG',
    'STATS_Q3.PNG',
    'STATS_Q5.PNG',
    '23-PCQ1b.PNG',
    '23-PCQ3ai.PNG',
    '23-PCQ3aii.PNG',
    '23-PCQ3ci.PNG',
    '23-PCQ3e.PNG',
    '23-P1Q3ai.PNG',
    '23-P1Q3aii.PNG',
    'Bio-Mi-24-Q2-graph.png',
    'Bio-Mi-24-Q5c-membrane.jpg',
    'Bio-Mi-24-Q2-graph-smaller.png',
    '2025-P1-Q1a.png',
    '2025-P1-Q2.png',
    '2025-P1-Q2c.png',
    '2025-P1-Q3-c.png',
    '2025-P1-Q3d.png',
    '2025-P2-Q1-b.png',
    '2025-P2-Q2-d.png',
    '2025-P2-Q3-a.png',
    '2025-P2-Q3-c.png',
    '2025-PCM-Q1-d.png',
    '2025-PCM-Q1.png',
    '2025-PCM-Q2-aii.png',
    '2025-PCM-Q2-bii.png',
    '2025-PCM-Q2-e.png',
    '2025-PCM-Q2.png',
    '2025-PCM-Q2aii_bi_bii.png',
    '2025-PCM-Q2b-and-biii.png',
    '2025-PCM-Q3-All.png',
    '2025-PCM-Q3-biii.png',
    '2025-PCM-Q3-C-ii_plus_table.png',
    '2025-PCM-Q3-C-ii_graphaxi.png',
    '2025-PCM-Q3b.png',
    '2025-PCM-Q4-c_ck_box.png',
    'P1Q3c_2.png',
    'P1Q4a.png',
    'P2Q3c.png',
    'P2Q3c_i.png',
    'P2Q3c_ii.png',
    'PiQ1c.png',
    'P1Q2_c_resize.png',
    'GRAPH_PAPER_2.png',
    '25KYP3C.png',
    '25KYP1A.png',
    'Cladogram_B.png',
    'GRAPH_PAPER.png',
    'Cladogram_A.png',
    'Cladogram_1.png',
    '25KYP2C.png',
    '25KYP2A.png',
    'Graph_1.png',
    'Graph_2.png',
    'BIOQ2graph.png'



];

const resetPart = {
    id: -1,
    part: '',
    page_count: 0,
    range: 0,
    prompt: '',
    image: '',
    comments: [],
};

const resetInput = {
    ...resetPart,
    question_id: 0,
    question_number: 0,
    format: '',
    heading: '',
    weight: 0,
    parts: [],
    comments: [],
};

const PartRow = ({partValues, edit, onEdit, onSave, onAdd}) => {
    const [part, setPart] = useState(resetPart);
    useEffect(() => {
        setPart({...partValues});
    }, [partValues]);
    const onChange = event => {
        const {name, value} = event.target;
        setPart({
            ...part,
            [name]: value,
        });
    };
    const subPartChange = (a, b) => {
        setPart({
            ...part,
            part: a + '.' + b,
        });
    };
    const handleSave = () => {
        onSave(part);
    };
    const handleDelete = () => {
        onSave({delete: part.id});
    };
    const handleCommentChange = event => {
        const {name, value} = event.target;
        onSave({...part, [name]: value});
    };
    const path = part.part.split('.');

    return (
        <>
            {edit ? (
                <tr>
                    {path.length === 2 ? (
                        <td className='form-inline'>
                            <FormGroup>
                                <Label>
                                    <strong>{path[0]}.</strong>
                                </Label>
                                <Input
                                    name='part'
                                    onChange={e => subPartChange(path[0], e.target.value)}
                                    value={path[1]}
                                    bsSize='sm'
                                />
                            </FormGroup>
                        </td>
                    ) : (
                        <td>
                            <Input name='part' onChange={onChange} value={part.part} bsSize='sm' />
                        </td>
                    )}
                    <td>
                        <Input
                            type='number'
                            name='page_count'
                            onChange={onChange}
                            value={part.page_count}
                            bsSize='sm'
                        />
                    </td>
                    <td>
                        <Input
                            name='prompt'
                            id='partPrompt'
                            onChange={onChange}
                            value={part.prompt}
                            bsSize='sm'
                        />
                    </td>
                    <td>
                        <Input
                            type='select'
                            name='image'
                            id='partImage'
                            onChange={onChange}
                            value={part.image}
                            bsSize='sm'
                        >
                            {promptImages.map(i => (
                                <option key={i}>{i}</option>
                            ))}
                        </Input>
                    </td>
                    <td>
                        <Input
                            type='number'
                            name='range'
                            id='partRange'
                            onChange={onChange}
                            value={part.range}
                            bsSize='sm'
                        />
                    </td>
                    <td>
                        <i
                            className='fa fa-check icon-primary'
                            tabIndex={0}
                            onClick={handleSave}
                            id={`tooltip-save-part-${part.id}`}
                            style={{paddingRight: '6px'}}
                        />
                        <UncontrolledTooltip
                            placement='right'
                            target={`tooltip-save-part-${part.id}`}
                        >
                            Save Part
                        </UncontrolledTooltip>
                        <i
                            className='fa fa-trash icon-danger'
                            tabIndex={1}
                            onClick={handleDelete}
                            id={`tooltip-delete-part-${part.id}`}
                        />
                        <UncontrolledTooltip
                            placement='right'
                            target={`tooltip-delete-part-${part.id}`}
                        >
                            Delete Part
                        </UncontrolledTooltip>
                    </td>
                </tr>
            ) : (
                <tr key={part.id}>
                    <td>{part.part}</td>
                    <td>{part.page_count}</td>
                    <td>{part.prompt}</td>
                    <td>{part.image}</td>
                    <td>{part.range}</td>
                    <td>
                        <i
                            className='fa fa-edit icon-primary'
                            onClick={onEdit}
                            tabIndex={0}
                            id={`tooltip-edit-${part.id}`}
                        />
                        <UncontrolledTooltip placement='right' target={`tooltip-edit-${part.id}`}>
                            Edit
                        </UncontrolledTooltip>
                        {path.length === 1 && (
                            <React.Fragment>
                                <i
                                    tabIndex={1}
                                    className='fa fa-plus icon-primary'
                                    onClick={() => onAdd(part.id)}
                                    id={`tooltip-part-${part.id}`}
                                />
                                <UncontrolledTooltip
                                    placement='right'
                                    target={`tooltip-part-${part.id}`}
                                >
                                    Add Part
                                </UncontrolledTooltip>
                            </React.Fragment>
                        )}
                    </td>
                </tr>
            )}
            <tr>
                <td colSpan={7}>
                    <CommentTable comments={part.comments || []} onChange={handleCommentChange} />
                </td>
            </tr>
        </>
    );
};

const QParts = ({disabled, id, parts, editing, onEdit, onSave, onAdd}) => {
    if (disabled) return null;
    return (
        <table className='table table-hover table-condensed'>
            <thead>
                <tr>
                    <th>Part</th>
                    <th>Pages</th>
                    <th>Prompt</th>
                    <th>Image</th>
                    <th>Range</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {parts.map(part => (
                    <PartRow
                        key={part.id}
                        partValues={part}
                        edit={editing === part.id}
                        onEdit={() => onEdit(part.id)}
                        onSave={onSave}
                        onAdd={onAdd}
                    />
                ))}
                <tr>
                    <td colSpan={5} />
                    <td>
                        <i
                            className='fa fa-plus icon-primary'
                            onClick={() => onAdd()}
                            tabIndex={3}
                            id={`tooltip-add-part-${id}`}
                        />
                        <UncontrolledTooltip placement='right' target={`tooltip-add-part-${id}`}>
                            Add Part
                        </UncontrolledTooltip>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const QuestionModal = ({isOpen, exam_id, question_id, mcImages, toggle}) => {
    const [input, setInput] = useState(resetInput);
    const [editing, setEditing] = useState(null);
    const {questionsData, formatsData, saveQuestion, deleteQuestion} =
        useExamQuestionsData(exam_id);

    useEffect(() => {
        setInput(
            questionsData
                ? questionsData.filter(q => q.id === question_id)[0] || resetInput
                : resetInput
        );
    }, [questionsData, question_id]);

    const handleAddPart = parent => {
        const addPartId =
            (input.parts ? input.parts.reduce((p, c) => Math.min(p, c.id), 0) : 0) - 1;
        let count = 0;
        if (parent) {
            // find last subpart of parent and add new subpart entry after
            let parentIndex = 0;
            let parentPart;
            input.parts.forEach((p, idx) => {
                // assuming we can do these in one loop because the query result is ordered by `part`
                if (parent === p.id) parentPart = p.part;
                if (parentPart && p.part.startsWith(parentPart)) {
                    count++;
                    parentIndex = idx;
                }
            });
            parentIndex++;
            setInput({
                ...input,
                parts: [
                    ...input.parts.slice(0, parentIndex),
                    {...resetPart, id: addPartId, part: parentPart + '.' + romanCount(count)},
                    ...input.parts.slice(parentIndex, input.parts.length),
                ],
            });
        } else {
            // add new part entry to end
            input.parts.forEach(p => {
                if (p.part.split('.').length === 1) count++;
            });
            setInput({
                ...input,
                page_count: 0,
                parts: [
                    ...input.parts,
                    {...resetPart, id: addPartId, part: letterCount(count + 1)},
                ],
            });
        }
        setEditing(addPartId);
    };
    const handleSavePart = part => {
        setEditing(null);
        if (part.delete) {
            const partName = input.parts.filter(p => p.id === part.delete)[0].part;
            setInput({...input, parts: input.parts.filter(p => !p.part.startsWith(partName))});
        } else setInput({...input, parts: input.parts.map(p => (p.id === part.id ? part : p))});
    };
    const handleChange = event => {
        const {name, value} = event.target;
        setInput({
            ...input,
            [name]: value,
        });
    };
    const handleSave = () => {
        setEditing('saving');
        // ensure any empty strings are zeroes where the API expects a number
        const payload = {
            ...input,
            page_count: input.page_count || 0,
            range: input.range || 0,
            weight: input.weight || 0,
            parts: input.parts.map(part => ({
                ...part,
                page_count: part.page_count || 0,
                range: part.range || 0,
            })),
        };
        saveQuestion(payload, {
            onSuccess: () => {
                setEditing(null);
                toggle();
            },
        });
    };
    const handleDelete = () => {
        setEditing('deleting');
        deleteQuestion(input.id, {
            onSuccess: () => {
                setEditing(null);
                toggle();
            },
        });
    };

    const isBubble = input.question_number === 0;
    return (
        <Modal size='lg' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Edit Question {input.question_number} for {input.exam}
            </ModalHeader>
            <ModalBody>
                <QFormatHeading
                    disabled={isBubble}
                    heading={input.heading}
                    format={input.format}
                    formats={formatsData}
                    onChange={handleChange}
                />
                {input.parts.length === 0 && (
                    <>
                        <QPageCount
                            disabled={isBubble}
                            page_count={input.page_count}
                            onChange={handleChange}
                        />
                        <MCQPrompt
                            disabled={input.format !== 'mc'}
                            options={mcImages}
                            prompt={input.heading}
                            onChange={handleChange}
                        />
                        <QRange range={input.range} onChange={handleChange} />
                    </>
                )}
                <QWeight weight={input.weight} onChange={handleChange} />
                <QParts
                    disabled={input.format !== 'open_response'}
                    id={input.id}
                    parts={input.parts}
                    editing={editing}
                    onEdit={setEditing}
                    onSave={handleSavePart}
                    onAdd={handleAddPart}
                />
                <CommentTable
                    disabled={input.format !== 'essay' && input.parts.length > 0}
                    comments={input.comments}
                    onChange={handleChange}
                    isEssay={input.format === 'essay'}
                />
            </ModalBody>
            <ModalFooter>
                {editing === 'saving' ? (
                    <Spinner />
                ) : (
                    <Button color='primary' onClick={handleSave}>
                        Save
                    </Button>
                )}{' '}
                {editing === 'deleting' ? (
                    <Spinner />
                ) : (
                    <Button color='danger' onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

function letterCount(value) {
    if (!value) return 'A';
    if (value > 702) return '';
    const zeroIndex = value - 1;
    let letters = '';
    const div = Math.trunc(zeroIndex / 26);
    if (div > 0) letters = String.fromCharCode(64 + div);
    letters += String.fromCharCode(65 + (zeroIndex % 26));
    return letters;
}

function romanCount(value) {
    if (!value) return 'i';
    const digits = String(+value).split('');
    const key = [
        '',
        'c',
        'cc',
        'ccc',
        'cd',
        'd',
        'dc',
        'dcc',
        'dccc',
        'cm',
        '',
        'x',
        'xx',
        'xxx',
        'xl',
        'l',
        'lx',
        'lxx',
        'lxxx',
        'xc',
        '',
        'i',
        'ii',
        'iii',
        'iv',
        'v',
        'vi',
        'vii',
        'viii',
        'ix',
    ];
    let roman = '',
        i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
    return Array(+digits.join('') + 1).join('M') + roman;
}

export default QuestionModal;
